<template>
    <v-container v-resize="onResize">
        <v-row align="center" justify="center" style="margin-left: 32px">
            <v-col cols="12" md="6" sm="7">
                <v-row no-gutters>
                    <v-col cols="12">
                        <img
                            :src="require('../assets/logo.svg')"
                            :class="logoClass"
                        >
                        <div
                            class="grey--text text--darken-3" 
                            :class="logoSubtitleClass"
                        >
                            USB Universal Tethering
                        </div>
                        <div
                            class="mt-2 grey--text text--darken-2"
                            :class="descriptionClass"
                        >
                            Tether for those who are always on the go.<br>
                            Reverse tether to reduce lag on online games.
                        </div>
                    </v-col>
                    <v-row no-gutters class="mt-2">
                        <v-col cols="12" md="6" class="mt-4 mr-4" style="max-width: 200px">
                            <!-- eslint-disable max-len -->
                            <a href="https://play.google.com/store/apps/details?id=com.robskie.tether">
                                <v-img
                                    :src="require('../assets/playstore-button.svg')"
                                    contain
                                    height="58px"
                                    position="left"
                                />
                            </a>
                            <!-- eslint-enable -->
                        </v-col>
                        <v-col cols="12" md="6" class="mt-4" style="max-width: 210px">
                            <router-link to="/server">
                                <v-img
                                    :src="require('../assets/server-button.svg')"
                                    contain
                                    height="58px"
                                    position="left"
                                />
                            </router-link>
                        </v-col>
                    </v-row>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" sm="5" style="max-width: 350px">
                <v-img
                    :src="require('../assets/phone.svg')"
                    :height="phoneHeight"
                    position="left"
                    contain
                />
            </v-col> 
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "HomePage",
    data() {
        return {
            window: {
                width: window.innerWidth,
                height: window.innerHeight  
            }
        };
    },
    computed: {
        size() {
            if (this.window.width > 640) { // computer
                return "large";
            } else if (this.window.width > 480) { // tablet
                return "medium";
            }
            return "small"; // phone
        },
        logoClass() {
            switch (this.size) {
            case "small":
            case "medium":
                return `logo-${this.size}`;
            default:
                return "logo";
            }
        },
        logoSubtitleClass() {
            switch (this.size) {
            case "small":
            case "medium":
                return `logo-subtitle-${this.size}`;
            default:
                return "logo-subtitle";
            }
        },
        descriptionClass() {
            switch (this.size) {
            case "small":
            case "medium":
                return `description-${this.size}`;
            default:
                return "description";
            }
        },
        phoneHeight() {
            switch (this.size) {
            case "small":
                return "500px";
            default:
                return "700px";
            }
        }
    },
    methods: {
        onResize() {
            this.window = {
                width: window.innerWidth,
                height: window.innerHeight
            };
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700i&display=swap');

.logo {
    height: 88px;
    margin-left: -32px;
}

.logo-medium {
    height: 80px;
    margin-left: -30px;
}

.logo-small {
    height: 72px;
    margin-left: -28px;
}

.logo-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
}

.logo-subtitle-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 15.5px;
}

.logo-subtitle-small {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.description {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
}

.description-medium {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
}

.description-small {
    font-family: 'Lato', sans-serif;
    font-size: 13px;
}

</style>
