<template>
    <div>
        <div id="header"/>
        <v-container fluid class="pa-0" style="margin-top: -48px">
            <v-row no-gutters>
                <div id="header"/>
                <v-col cols="2"/>
                <v-col cols="8">
                    <v-row no-gutters>
                        <v-col cols="12" :class="{'text-center': $vuetify.breakpoint.xs}">
                            <router-link to="/"> 
                                <img
                                    id="logo"
                                    src="../assets/logo.svg"
                                >
                            </router-link>         
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12">
                            <div :id="titleID" class="text-center">
                                Download server application
                            </div>
                        </v-col>
                    </v-row>

                    <v-row no-gutters align="center" class="text-center">
                        <v-col cols="12" md="4">
                            <div class="os-container">
                                <img class="windows-image" src="../assets/windows.svg">
                                <div class="os-text">Windows 10+</div>
                            </div>
                            <v-btn 
                                rounded
                                depressed
                                :ripple="false"
                                color="#1565C0"
                                class="white--text download-btn" 
                                large width="164px"
                                :href="getDownloadLink('win')"
                            >
                                Download
                            </v-btn>
                            <div style="min-height: 24px"/>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="os-container">
                                <img class="mac-image" src="../assets/mac.svg">
                                <div class="os-text">macOS 10.15+ (Intel)</div>
                            </div>
                            <v-btn 
                                rounded
                                depressed
                                :ripple="false"
                                color="#1565C0"
                                class="white--text download-btn" 
                                large width="164px"
                                :href="getDownloadLink('mac')"
                            >
                                Download
                            </v-btn>
                            <div style="min-height: 24px"/>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="os-container">
                                <img class="linux-image" src="../assets/linux.svg">
                                <div class="os-text">Linux</div>
                            </div>
                            <v-btn 
                                rounded
                                depressed
                                :ripple="false"
                                color="#1565C0"
                                class="white--text download-btn" 
                                large width="164px"
                                :href="getDownloadLink('linux_deb')"
                            >
                                Download
                            </v-btn>
                            <div style="min-height: 24px">
                                <a :href="getDownloadLink('linux_deb')" class="caption">DEB</a>
                                <span class="caption"> / </span>
                                <a :href="getDownloadLink('linux_rpm')" class="caption">RPM</a>
                                <span class="caption"> / </span>
                                <a :href="getDownloadLink('linux_pkg')" class="caption">PKG</a>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2"/>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "ServerPage",
    computed: {
        titleID() {
            const name = this.$vuetify.breakpoint.name;
            switch(name) {
            case "sm":
            case "xs":
                return "title-sm";
            }
            return "title";
        }
    },
    methods: {
        getDownloadLink(os) {
            const links = {
                win: "https://download.tetrd.app/files/tetrd.windows_amd64.exe",
                mac: "https://download.tetrd.app/files/tetrd.macos_universal.pkg",
                linux_deb: "https://download.tetrd.app/files/tetrd.linux_amd64.deb",
                linux_rpm: "https://download.tetrd.app/files/tetrd.linux_amd64.rpm",
                linux_pkg: "https://download.tetrd.app/files/tetrd.linux_amd64.pkg.tar.xz"
            };
            return links[os];
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat:600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

#header {
    height: 48px;
    background-color: #F5F5F5;
}

#title-sm {
    padding-top: 64px;
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
}

#title {
    padding-top: 64px;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    color: #212121;
}

#logo {
    height: 24px;
    margin-top: 8px;
}

.os-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 72px;
}

.os-text {
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    color: #212121;
}

.windows-image {
    height: 16px;
    margin-right: 8px;
}

.mac-image {
    height: 20px;
    margin-right: 8px;
    margin-bottom: 4px;
}

.linux-image {
    height: 24px;
    margin-right: 8px;
}

.download-btn {
    margin-top: 16px;
    font-family: 'Roboto', sans-serif;
}

.linux-links {
    font-size: 12px;
}

</style>
