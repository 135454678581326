import Home from "../src/pages/Home";
import Server from "../src/pages/Server";
import Privacy from "../src/pages/Privacy";

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    { path: "/", component: Home},
    { path: "/server", component: Server},
    { path: "/privacy", component: Privacy}
];

export default new VueRouter({
    routes,
    mode: "history"
});
