<template>
    <div class="container mt-4 mb-8">
        <div class="spacer"/>

        <div class="body">
            <h1>Privacy Policy</h1>
            <p class="mt-4">
                This policy applies to all information collected, used, and stored by Tetrd. 
                By using the app, you agree to the collection and use of information in relation 
                to this policy.
            </p>

            <h2 class="mt-8">Information we collect</h2>
            <p class="mt-4">
                The app offers a time-limited free trial. To facilitate this, the app stores the 
                hashed device ID and the free-trial expiry date in a database. These are then
                retrieved each time the app is used to check if the free trial has expired.
            </p>
            <p>
                The app also creates a local VPN on your device and a TUN/TAP adapter on your 
                computer. This allows your device and computer to communicate with each other via 
                USB as if they were on the same local network. This also allows your computer to 
                share its internet connection with your device. Note that the app doesn't use, 
                collect, or share any data that goes through the VPN.
            </p>

            <h3>Log data</h3>  
            <p class="mt-4">
                In case of an error, the app collects data and information on your phone. 
                This data may include information such as your device IP address, device name, 
                operating system version, app configuration, time and date, and other 
                statistics.
            </p>
            <p>
                The app's remote server also stores basic technical information such as your IP 
                address in temporary logs.
            </p>

            <h3>Cloudflare</h3>
            <p class="mt-4">
                For performance and overload protection, we direct your traffic through 
                Cloudflare before it reaches the app's servers. They have access to some basic 
                technical information to perform this role such as your IP address. You can 
                access Cloudflare's privacy policy
                <a href="https://www.cloudflare.com/privacypolicy">
                    here.
                </a>
            </p>

            <h3>Third-party services</h3>
            <p class="mt-4">
                The app also uses third party services that may collect information used to 
                identify you. The following are links to privacy policies of third party 
                services used by the app.
                <ul class="mt-2 ml-4">
                    <li>
                        <a href="https://www.google.com/policies/privacy">
                            Google Play Services
                        </a>
                    </li>
                    <li>
                        <a href="https://www.google.com/analytics/terms/">
                            Google Analytics
                        </a>
                    </li>
                </ul>
            </p>

            <h2 class="mt-8">Information usage</h2>
            <p class="mt-4">
                All information we collect is used to operate and improve the app. We do not 
                share personal information with outside parties except to the extent necessary 
                to accomplish the app's functionality. 
            </p>
            <p>
                We may disclose your information in response to subpoenas, court orders, or 
                other legal requirements; to exercise our legal rights or defend against legal 
                claims; to investigate, prevent, or take action regarding illegal activities, 
                suspected fraud or abuse, violations of our policies; or to protect our rights 
                and property.
            </p>

            <h2 class="mt-8">Data retention</h2>
            <p class="mt-4">
                We only retain data necessary for app functionality and improvement. These 
                include hashed device IDs and log data. These data are stored for up to 90 
                days from the date they were first collected.
            </p>

            <h2 class="mt-8">Security</h2>
            <p class="mt-4">
                We implement a variety of security measures to help keep your information 
                secure. For instance, all communication with the app and remote server requires 
                HTTPS. Device IDs are also hashed before storing them in a database.
            </p>

            <h2 class="mt-8">Third-party links</h2>
            <p class="mt-4">
                The app and the app's website may contain links to other sites. These have 
                their own independent privacy policies, and we have no responsibility or 
                liability for their content or activities.
            </p>

            <h2 class="mt-8">Children's privacy</h2>
            <p class="mt-4">
                We do not knowingly collect personally identifiable information from children 
                under 13. No part of the app and the app's website is structured to attract 
                anyone under 13.
            </p>

            <h2 class="mt-8">Changes to this policy</h2>
            <p class="mt-4">
                We may update this privacy policy from time to time. Thus, you are advised to 
                review this page periodically for any changes.
            </p>
        </div>
        <div class="spacer"/>
    </div>
</template>

<script>
export default {
    name: "PrivacyPage",
    computed: {
        bodyClass() {
            switch(this.$vuetify.breakpoint.name){
            case "xs":
                return "body-xs";
            default:
                return "body";
            }
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');

h1, h2, h3, p {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    color: #424242;
}

.body {
    max-width: 700px;
}

.spacer {
    min-width: 8px;
}

.container {
    display: flex;
    justify-content: center;
}

</style>